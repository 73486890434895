:root {
  --purpose-color: rgb(188, 124, 203); /* #BC7CCB */
  --purpose-background-color: rgba(188, 124, 203, 0.2);
  --strategy-color: rgb(236, 131, 97); /* #EC8361 */
  --tactic-color: rgb(83, 175, 246); /* #53AFF6 */
  --project-color: rgb(103, 197, 126); /* #67C57E */
  --portfolio-color: rgb(239, 188, 6); /* #EFBC06 */
}

.purpose-background-color {
  background-color: rgba(188, 124, 203, 0.2);
}
.purpose-color {
  color: var(--purpose-color);
}
.purpose-border-color {
  border-color: rgba(188, 124, 203, 0.8);
}


.strategy-background-color {
  background-color: rgba(236, 131, 97, 0.2);
}
.strategy-color {
  color: var(--strategy-color);
}
.strategy-border-color {
  border-color: rgba(236, 131, 97, 0.8);
}


.tactic-background-color {
  background-color: rgba(83, 175, 246, 0.2);
}
.tactic-color {
  color: var(--tactic-color);
}
.tactic-border-color {
  border-color: rgba(83, 175, 246, 0.8);
}


.project-background-color {
  background-color: rgba(103, 197, 126, 0.2);
}
.project-color {
  color: var(--project-color);
}
.project-border-color {
  border-color: rgba(103, 197, 126, 0.8);
}


.portfolio-background-color {
  background-color: rgba(239, 188, 6, 0.2);
}
.portfolio-color {
  color: var(--portfolio-color);
}
.portfolio-border-color {
  border-color: rgba(239, 188, 6, 0.8);
}


* {
  box-sizing: border-box;
}
