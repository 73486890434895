.landing-page-container {
  background-color: black;
  min-height: 100vh;
}

.landing-page-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #FFF;
  height: 135px;
  padding: 40px 45px 45px 35px;
}

.landing-page-header :last-child {
  padding: -2px;
}

.landing-page-list-container a{
  text-decoration: none;
}

.landing-page-list-container ul > div {
  margin-bottom: 1.875vw;
  padding-bottom: 0;
}

.landing-page-list {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 80px;
}

.landing-page-list div {
  color: white;
}

.landing-page-list > div {
  width: 31.25vw;
}

.landing-page-select-one {
  margin-left: 30px;
  margin-bottom: 20px;
  letter-spacing:  0.20em;
}

.landing-page-letter-surround {
  display: flex;
  width: 31.25vw;
  min-width: 260px;
  border: 2px solid #FFF;
  border-radius: 10px;
  align-items: center;
  letter-spacing:  0.35em;
  pointer-events: none;
}

.landing-page-first-letter {
  font-size: 4vw;
  font-weight: 900;
  border-right: 2px solid #fff;
  padding: 0 1.87vw;
}

.landing-page-hover-border {
  border: 2px solid #78ab42;
}

.landing-page-hover-border-right {
  border-right: 2px solid #78ab42;
}

.landing-page-letter-surround .landing-page-end-letters.landing-page-text-hover,
.landing-page-first-letter.landing-page-hover-border-right.landing-page-text-hover {
  color: #78ab42;
}

.landing-page-end-letters {
  font-size: 3.1vw;
  padding-left: 20px;
}