.visualization-container-message {
  position: absolute;
  top: 10px;
  left: 10px;
  background-color: snow;
  width: 200px;
  height: 40px;
  text-align: center;
  border-radius: 5px;
  padding-top: 10px;
}
