.content-display {
  position: relative;
  min-width: 100%;
  padding: 5px 15px 5px 5px;
  /* color: #FFF; */
  border-radius: 10px;
  border: 1px solid;
  text-align: left;
}

.content-display:not(:last-child) {
  margin-bottom: 10px;
}
.content-display > .content-display-name {
  margin-bottom: 0;
  padding-left: 5px;
  padding-top: 5px;
  font-size: 18px;
  min-height: 50px;
  vertical-align: middle;
}

.content-display.expanded > .content-display-name {
  margin-bottom: 20px;
}

.content-display-name {
  font-size: 20px;
  flex-grow: 90;
}

.content-display-top {
  display: flex;
  flex-wrap: nowrap;
  margin-bottom: 20px;
}

.content-display-tag {
  float: right;
  vertical-align: top;
  flex-basis: 10px;
  align-self: flex-start;
  justify-content: right;
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
  /* border: solid 1px yellow; */
  background-color: black;
  margin-right: -11px;
  margin-left: 5px;
  margin-top: -2px;
  border-radius: 5px;
  padding: 3px;
  font-size: 12px;
  /* font-weight: bold; */
}

.content-display-selected {
    border: 3px solid;
    position: relative;
}

.content-display .content-display:not(.has-peers) {
  margin-right: -10px;
}

.STRATEGIES-container.has-peers {
    box-shadow: 
      3px -3px black,
      5px -5px rgba(236, 131, 97, .4),
      8px -8px black,
      10px -10px rgba(236, 131, 97, .2);
}

.TACTICS-container.has-peers {
    box-shadow: 
      3px -3px rgba(0, 0, 0, .4),
      5px -5px rgba(83, 175, 246, .4),
      8px -8px rgba(0, 0, 0, .4),
      10px -10px rgba(83, 175, 246, .2);
}

.PROJECTS-container.has-peers {
    box-shadow: 
      3px -3px rgba(0, 0, 0, .4),
      5px -5px rgba(103, 197, 126, .4),
      8px -8px rgba(0, 0, 0, .4),
      10px -10px rgba(103, 197, 126, .2);
}

.content-display-selected.STRATEGIES-container {
  min-height: 53vh;
}

.content-display-selected.TACTICS-container {
  min-height: 46.5vh;
}

.content-display-selected.PROJECTS-container {
  min-height: 40vh;
}

.edit-dialog-background {
  background-color: #1a1b1f;
}

.white-color {
  color: white;
}

.content-display-add-button {
  position: absolute;
  bottom: 5px;
  right: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid;
  border-radius: 8px;
  height: 26px;
  width: 26px;
  cursor: pointer;
}

.valid-drop-target {
  border: 2px dashed white;
}

.hovering-over-drop-target {
  border: 4px solid white;
}

.bottom-placeholder-div {
  height: 31px;
}

svg.content-display-header-icon {
  position: relative;
  top: 2px;
  left: 5px;
  width: 18px;
  height: 18px;
  margin: 0 5px;
  cursor: pointer;
}

.delete-icon {
  color: #DC0C0C;
  cursor: pointer;
}

.dragging {
  cursor: grabbing;
  cursor: -moz-grabbing;
  cursor: -webkit-grabbing;
}