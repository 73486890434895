#resource-tree {
  padding: 40px 50px 0px 50px;
}

.resource-tree-goal-comments-icon {
  position: absolute;
  right: 15px;
}

.hidden-scroll {
  overflow-y: auto;
  -webkit-overflow-scrolling: auto;
}

#resource-tree::-webkit-scrollbar {
  display: none;
}

.resource-tree-header-container::-webkit-scrollbar {
  display: none;
}

#resource-tree .resource-tree-header-container,
#resource-tree .resource-tree-content-container {
  display: flex;
  justify-content: space-between;
}

.add-strategy-button-container {
  display: flex;
  justify-content: flex-end;
}

.content-display-add-strategy-button {
  position: sticky;
  margin-right: -30px;
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid;
  border-radius: 8px;
  height: 26px;
  width: 26px;
  cursor: pointer;
}

#resource-tree .resource-tree-header-container > :not(:last-child),
#resource-tree .resource-tree-content-container > :not(:last-child) {
  margin-right: 50px;
}

.selected-text {
  text-align: left;
  margin-bottom: 30px;
  background: #000000;
  color: #FFFFFF;
  font-size: 24px;
}

.description {
  white-space: pre-line;
  margin-bottom: 10px;
}

.description a {
  color: rgba(255, 255, 255, 0.9);
}