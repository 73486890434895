.vis-slider-container {
  position: absolute;
  bottom: -5px;
  padding-left: 30px;
  padding-right: 30px;
  width: 100%;
  display: flex;
}

.vis-slider-controls-container {
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
  margin-right: 10px;
}

.vis-slider-controls-container button {
  padding: 0 6px;
  padding-top: 2px;
}

.vis-slider-controls-container > :not(:last-child) {
  margin-right: 5px;
}

.vis-date-slider .MuiSlider-thumb{
  transition-property: left;
  transition-duration: 0.5s;
}