.content-container {
  display: flex;
  justify-content: space-between;
  flex-basis: 100vh;
  flex-shrink: 1;
  flex-grow: 1;
  color: #a5a5a5;
  min-height: 450px;
  min-width: 300px;
  padding: 50px;
  background-color: #000;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  border-left: 1px solid var(--purpose-color);
  border-right: 1px solid var(--purpose-color);
  overflow-y: auto;
  height: calc(100vh - 350px);
}

.simplebar-scrollbar::before {
  background-color: var(--strategy-color);
}

.content-container > :not(:last-child){
  margin-bottom: 40px;
}

.content-display-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  column-gap: 25px;
  row-gap: 25px;
}

.content-display-container.full-width {
  display: block;
}

.content-display-container > .content-display {
  margin-bottom: 0;
}

.content-type-badge-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.content-type-badge-container:not(:only-child) {
  max-width: 180px;
  min-width: 180px;
  margin-left: 75px;
}

.content-type-badge-container > :not(:last-child) {
  margin-bottom: 40px;
}

.content-type-badge-container:not(:only-child) > :not(:last-child) {
  margin-bottom: 30px;
}