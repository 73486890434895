.deleted-row {
  background-color: #fce4ec;
}

.deleted-row td {
  color: #777777;
}

.editable-table td {
  display: flex;
  align-items: center;
}

.editable-table td > * {
  width: 100%;
}

.editable-table thead tr:last-child th {
  padding: 5px;
}

.editable-table .th-filter * {
  width: 100%;
}

.editable-table-container {
  max-height: calc(100vh - 94px);
}

.editable-table-container .editable-table thead {
  display: block;
  position: sticky;
  top: 0;
}

.editable-table-button-container {
  display: flex;
  justify-content: flex-end;
  padding: 5px;
}

.editable-table-button-container > button {
  width: 100px;
}

.editable-table-button-container > :not(:last-child) {
  margin-right: 5px;
}