#header .MuiAutocomplete-root {
  flex-basis: 200px;
  flex-grow: 0.7;
}

#header .MuiAutocomplete-root *, #events .MuiAutocomplete-root * {
  color: #A5A5A5 !important;
}

.search-results {
  width: 100%;
  max-height: 300px;
  padding: 0px;
  margin: 0px;
  background-color: inherit;
  position: relative;
  overflow: auto;
}

.other-other-search-popper.MuiAutocomplete-popper .MuiAutocomplete-paper,
.search-result-section {
  background-color: #212027;
}

.other-other-search-popper.MuiAutocomplete-popper .MuiAutocomplete-paper .MuiAutocomplete-loading {
  color: #A5A5A5;
} 

.search-result-group {
  padding: 0;
}

.search-result-group li,
.search-result-group li div {
  padding: 0;
}

.search-result-group .search-result-group-header {
  background-color: inherit;
  padding-left: 10px;
  font-weight: bold;
}

.search-result-item {
  height: 36px;
}

.search-results .MuiAutocomplete-option[aria-selected="true"] {
  background: none;
}

.search-results .MuiAutocomplete-option[data-focus="true"] {
  background-color: rgba(255, 255, 255, 0.04);
}



.search-result-item .search-result-item-icon-container {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 36px;
  height: 33px;
  margin: 0px 4px;
  border-radius: 4px;
}

.search-result-item .search-result-item-text {
  display: flex;
  align-items: center;
  height: inherit;
  margin: 0;
  padding-left: 10px;
  color: #fff;
}

.search-result-item .search-result-item-text span {
  margin: 0;
}