#sidebar {
  background: #000000;
}

#sidebar a {
  text-decoration: none;
}

#sidebar.temp-sidebar {
  width:240px;
  margin: 0;
}
                                                                                         
#sidebar.temp-sidebar-closed {
  width: 70px;
}

.temp-sidebar-closed .menu-bar-first-letter + span {
  display:none
}


#sidebar .sidebar-logo-container {
  padding: 40px 45px 45px 35px;
  display: flex;
  flex-flow: column;
}

#sidebar.temp-sidebar-closed .sidebar-logo-container {
  padding: 30px 2px;
}

.temp-sidebar-closed .sidebar-logo-container img{
  width: 60px;
}

#sidebar .sidebar-icon {
  color: white;
  font-size: 1.8rem;
  margin-top: 25px;

}

#sidebar.temp-sidebar .sidebar-icon {
  text-align: left;
}

#sidebar.temp-sidebar-closed .sidebar-icon {
  text-align: center;
  margin-left: 12px;
}


#sidebar .sidebar-list-item {
  padding: 0 35px;
  height: 64px;
}


#sidebar .sidebar-list-item * {
  color: #FFFFFF;
}

#sidebar.temp-sidebar-closed .sidebar-list-item {
  padding: 14px;
}

#sidebar .sidebar-list-item.selected * {
  color: #78ab42;
}

#sidebar .sidebar-list-item.selected,
#sidebar .sidebar-list-item:hover {
  background: #1A1A1A;
}

#sidebar .menu-bar-first-letter {
  float: left;
  border: 1px solid rgba(165, 165, 165, 0.5);
  border-radius: 4px;
  background-color: #1A1B1F;
  padding-left: 6px;
  padding-right: 6px;
  margin-right: 3px;
  margin-top: -10px;
  font-weight: 800;
  font-size: 24px;
  color: rgb(163, 245, 207);
}

.menu-bar-manage-data {
  position: absolute;
  bottom: 0;
  font-size: 1.6rem;
  padding: 10px 46px;
  color: rgb(163, 245, 207);
}
.menu-bar-manage-data span{
  font-size: 1rem;
  color: white;
vertical-align: middle;
  position: relative;
  bottom:5px;
}

 .temp-sidebar-closed .menu-bar-manage-data {
  padding:10px; 
  left: 7px;
}