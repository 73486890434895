.content-header {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 10px;
  border-bottom: 1px solid var(--purpose-color);
  border-top: 1px solid var(--purpose-color);
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  height: 130px;
  color: var(--purpose-color);
  background-color: #000;
  flex-basis: 100%;
  font-size: 24px;
  cursor: pointer;
  border-left: 1px solid var(--purpose-color);
  border-right: 1px solid var(--purpose-color);
  min-width: 300px;
}

.header-edit-modal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: var(--purpose-color);
  background-color: #000;
  opacity:0.93;
  flex-basis: 100%;
  height: 70vh;
  min-width: 300px;
  border-bottom: 1px solid var(--purpose-color);
  border-top: 1px solid var(--purpose-color);
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}
.header-edit-modal input {
  color: var(--purpose-color)
}
.header-edit-modal button {
  color: var(--purpose-color)
}
.content-header-background {
  /* background: green !important; */
  min-width: 100px;
  font-size: small;
  height: 100px;
  position: relative;
  bottom: -30px;
}

.content-header:hover {
  opacity: 1 !important;
}

.header-edit-modal h2 {
  font-weight:200;
}